<template>
    <dashboard-layout>
        <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="gigs"
            :items-per-page="15"
            class="elevation-1"
            @click:row="handleClickRow"
        >
            <template v-slot:item.created_at="{ item }">
                {{ $date(item.created_at).format('MMM DD, YYYY HH:mm') }}
            </template>
        </v-data-table>
    </dashboard-layout>
</template>

<script>
import Gig from '@/models/Gig';
import DashboardLayout from "@/layouts/DashboardLayout";

export default {
    name: "users-index",
    components: {DashboardLayout},
    data: function () {
        return {
            loading: false,
            gigs: [],
            headers: [
                {
                    text: '# ID',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                },
                {text: 'Title', value: 'title'},
                {text: 'Created at', value: 'created_at'},
            ],
        }
    },
    methods: {
        handleClickRow(gig) {
            this.$router.push({name: 'admin.gigs.show', params: {id: gig.id}})
        }
    },
    async mounted() {
        this.loading = true
        this.gigs = await Gig.get()
        this.loading = false
    },
}
</script>

<style scoped>

</style>
